import { Button } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React from "react";

import DraggableIdealList from "@/components/DraggableIdeaList";
import ExternalListForm from "@/components/ExternalListForm";
import IdeaForm from "@/components/IdeaForm";
import IdeaList from "@/components/IdeaList";
import PageContent from "@/components/PageContent";
import PageHeader from "@/components/PageHeader";
import { useIndexExternalListsQuery } from "@/hooks/useExternalLists";
import { useIndexIdeasQuery } from "@/hooks/useIdeas";
import useToggle from "@/hooks/useToggle";
import type { WishlistCategory, WishlistRecord } from "@/types/wishlists";

import ExternalLists from "./ExternalLists";
import ManageDropdown from "./ManageDropdown";

export type WishlistProps = {
  category: WishlistCategory;
  wishlist: WishlistRecord;
};

const Wishlist = ({ category, wishlist }: WishlistProps) => {
  const {
    open: ideaModalOpen,
    handleOpen: handleIdeaModalOpen,
    handleClose: handleIdeaModalClose
  } = useToggle();
  const {
    open: externalListModalOpen,
    handleOpen: handleExternalListModalOpen,
    handleClose: handleExternalListModalClose
  } = useToggle();
  const { data: ideas } = useQuery(useIndexIdeasQuery(category, wishlist.id));
  const { data: externalLists } = useQuery(useIndexExternalListsQuery(category, wishlist.id));
  const IdeaListComponent = category === "my" ? DraggableIdealList : IdeaList;

  const hasExternalList = (externalLists || []).length > 0;

  return (
    <>
      <PageHeader title={wishlist.name} subtitle={wishlist.description}>
        {category === "my" && <ManageDropdown category={category} wishlist={wishlist} />}
        {category === "my" && (
          <>
            <Button
              variant="outlined"
              onClick={handleExternalListModalOpen}
              sx={{ whiteSpace: "nowrap" }}
            >
              Add External List
            </Button>
            <Button variant="contained" onClick={handleIdeaModalOpen} sx={{ whiteSpace: "nowrap" }}>
              Add Idea
            </Button>
          </>
        )}
      </PageHeader>
      <PageContent>
        {hasExternalList && <ExternalLists externalLists={externalLists || []} />}
        <IdeaListComponent category={category} wishlistId={wishlist.id} ideas={ideas || []} />
      </PageContent>
      {category === "my" && ideaModalOpen && (
        <IdeaForm
          category={category}
          wishlistId={wishlist.id}
          open={ideaModalOpen}
          onClose={handleIdeaModalClose}
        />
      )}
      {category === "my" && externalListModalOpen && (
        <ExternalListForm
          category={category}
          wishlistId={wishlist.id}
          open={externalListModalOpen}
          onClose={handleExternalListModalClose}
        />
      )}
    </>
  );
};

export default Wishlist;
