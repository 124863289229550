import { useConfirm } from "material-ui-confirm";

const useOpenExternalLink = () => {
  const confirm = useConfirm();

  return (href: string) => () => {
    confirm({
      title: "Open external link?",
      description: href,
      confirmationButtonProps: {
        autoFocus: true,
        //@ts-expect-error mui supports button link and target, but material-ui-confirm 3.0.11 has incorrect type for links
        target: "_blank",
        href
      },
      contentProps: {
        sx: {
          overflowWrap: "break-word",
          wordWrap: "break-word",
          wordBreak: "break-word"
        }
      }
    })
      .then(() => {})
      .catch(() => {});
  };
};

export default useOpenExternalLink;
