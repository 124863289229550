import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  TextField
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";

import useAppContext from "@/hooks/useAppContext";
import useRequest from "@/hooks/useRequest";
import { getErrorMessages } from "@/hooks/useRestApi";
import type { UserRecordPartial } from "@/types/users";

type AccountEmailFormProps = {
  open: boolean;
  onClose: () => void;
};

type AccountEmailFormData = {
  unconfirmedEmail: UserRecordPartial["unconfirmedEmail"];
  passwordChallenge: UserRecordPartial["passwordChallenge"];
};

const AccountEmailForm = ({ open, onClose }: AccountEmailFormProps) => {
  const { auth } = useAppContext();
  const request = useRequest();
  const {
    register,
    formState: { errors, isSubmitting },
    setError,
    reset,
    handleSubmit
  } = useForm<AccountEmailFormData>({
    defaultValues: {
      unconfirmedEmail: auth?.currentUser.email || "",
      passwordChallenge: ""
    }
  });
  const emaiRef = useRef<HTMLInputElement>();

  useEffect(() => {
    setTimeout(() => {
      emaiRef.current?.focus();
    }, 100);
  }, []);

  const handleClose = () => {
    reset();
    onClose();
  };

  const onSubmit: SubmitHandler<AccountEmailFormData> = (data) =>
    request
      .patch("/api/account/email", { data })
      .then(() => {
        handleClose();
      })
      .catch((error) => {
        const messages = getErrorMessages<UserRecordPartial>(error);
        if (messages?.unconfirmedEmail)
          setError("unconfirmedEmail", { message: messages.unconfirmedEmail });
        if (messages?.passwordChallenge)
          setError("passwordChallenge", { message: messages.passwordChallenge });
        reset(undefined, { keepValues: true, keepErrors: true });
      });

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Change email</DialogTitle>
        <DialogContent>
          <TextField
            label="Email"
            type="email"
            margin="normal"
            autoComplete="off"
            fullWidth
            required
            inputRef={emaiRef}
            slotProps={{ input: { inputProps: { "data-1p-ignore": true } } }}
            {...register("unconfirmedEmail")}
            error={!!errors.unconfirmedEmail?.message}
            helperText={errors.unconfirmedEmail?.message}
          />
          <TextField
            label="Password"
            type="password"
            margin="normal"
            autoComplete="current-password"
            required
            fullWidth
            {...register("passwordChallenge")}
            error={!!errors.passwordChallenge?.message}
            helperText={errors.passwordChallenge?.message}
          />
          {isSubmitting && <LinearProgress />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" disabled={isSubmitting}>
            Update
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AccountEmailForm;
