import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import { IconButton, ListItem, ListItemButton, ListItemText, Stack } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import React from "react";

import ExternalListForm from "@/components/ExternalListForm";
import useOpenExternalLink from "@/hooks/useExternalLink";
import { useDeleteExternalListMutation } from "@/hooks/useExternalLists";
import useToggle from "@/hooks/useToggle";
import { ExternalListRecord } from "@/types/external-lists";

type ExternaListProps = {
  externalList: ExternalListRecord;
};

const ExternaList = ({ externalList }: ExternaListProps) => {
  const { open: formOpen, handleOpen: handleFormOpen, handleClose: handleFormClose } = useToggle();
  const handleOpenLink = useOpenExternalLink();
  const confirm = useConfirm();

  const deleteMutation = useDeleteExternalListMutation(
    externalList.category,
    externalList.wishlistId,
    externalList.id
  );

  const handleDelete = () => {
    confirm({
      description: `This will permanently delete ${externalList.name}`,
      confirmationButtonProps: { autoFocus: true }
    })
      .then(() => {
        deleteMutation.mutate();
      })
      .catch(() => {});
  };

  return (
    <>
      <ListItem key={externalList.id} disableGutters disablePadding>
        <ListItemButton onClick={handleOpenLink(externalList.url)}>
          <ListItemText
            primary={externalList.name}
            secondary={externalList.comment}
            sx={{ my: 0 }}
          />
        </ListItemButton>
        {externalList.category == "my" && (
          <Stack direction="row" spacing={0.5}>
            <IconButton aria-label="edit" onClick={handleFormOpen}>
              <EditIcon />
            </IconButton>
            <IconButton aria-label="delete" onClick={handleDelete}>
              <DeleteIcon />
            </IconButton>
          </Stack>
        )}
      </ListItem>
      {formOpen && (
        <ExternalListForm
          category={externalList.category}
          wishlistId={externalList.wishlistId}
          open={formOpen}
          onClose={handleFormClose}
          externalList={externalList}
        />
      )}
    </>
  );
};

export default ExternaList;
