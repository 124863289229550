import { Tab, Tabs } from "@mui/material";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

import useAppContext from "@/hooks/useAppContext";
import type { WishlistCategory } from "@/types/wishlists";

type WishlistTabsProps = {
  category: WishlistCategory | "purchased";
};

const WishlistTabs = ({ category }: WishlistTabsProps) => {
  const { isMobile } = useAppContext();

  return (
    <Tabs value={category} variant={isMobile ? "fullWidth" : "standard"} sx={{ mb: 3 }}>
      <Tab label="My Wishlists" value="my" component={RouterLink} to="/my_wishlists" />
      <Tab label="Shared Wishlists" value="shared" component={RouterLink} to="/shared_wishlists" />
      <Tab label="Purchased Ideas" value="purchased" component={RouterLink} to="/purchased_ideas" />
    </Tabs>
  );
};

export default WishlistTabs;
