import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import type { FormEvent } from "react";
import React, { useState } from "react";

import { useUpdateIdeaMutation } from "@/hooks/useIdeas";
import { sortWishlists, useIndexWishlistsQuery } from "@/hooks/useWishlists";
import type { IdeaRecord } from "@/types/ideas";
import type { WishlistRecord } from "@/types/wishlists";

type MoveIdeaModalProps = {
  idea: IdeaRecord;
  open: boolean;
  onClose: () => void;
};

type WishlistChangeHandler = (
  event: React.MouseEvent<HTMLElement>,
  wishlistId: WishlistRecord["id"]
) => void;

const MoveIdeaModal = ({ idea, open, onClose }: MoveIdeaModalProps) => {
  const [selectedWishlist, setSelectedWishlist] = useState<WishlistRecord["id"]>();
  const { data: wishlists, isLoading } = useQuery(useIndexWishlistsQuery(idea.category));
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const updateIdea = useUpdateIdeaMutation(idea.category, idea.wishlistId);

  const otherWishlists = sortWishlists(
    (wishlists || []).filter((wishlist) => wishlist.id !== idea.wishlistId)
  );

  const onWishlistChange: WishlistChangeHandler = (_event, wishlistId) => {
    setSelectedWishlist(wishlistId);
  };

  const resetModal = () => {
    setSelectedWishlist(undefined);
    setErrorMessage(undefined);
    setIsSubmitting(false);
  };

  const handleClose = () => {
    resetModal();
    onClose();
  };

  const handleSave = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isSubmitting) return;

    setIsSubmitting(true);
    if (!selectedWishlist) {
      setErrorMessage("Please select a wishlist");
      setIsSubmitting(false);
    } else {
      updateIdea.mutate(
        { ideaId: idea.id, data: { wishlistId: selectedWishlist } },
        {
          onSuccess: () => {
            handleClose();
          },
          onError: () => {
            setErrorMessage("Something unexpected happened");
            setIsSubmitting(false);
          }
        }
      );
    }
  };

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ sx: { minWidth: "24rem" } }}>
      <form onSubmit={handleSave}>
        <DialogTitle>Move Idea</DialogTitle>
        <DialogContent>
          {isLoading && <CircularProgress />}
          {!isLoading && otherWishlists.length > 0 && (
            <>
              <ToggleButtonGroup
                orientation="vertical"
                value={selectedWishlist}
                exclusive
                onChange={onWishlistChange}
                fullWidth
              >
                {otherWishlists.map((wishlist) => (
                  <ToggleButton key={wishlist.id} value={wishlist.id}>
                    {wishlist.name}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
              {errorMessage && (
                <Typography color="error" variant="body1" sx={{ mt: 2 }}>
                  {errorMessage}
                </Typography>
              )}
            </>
          )}
          {!isLoading && otherWishlists.length === 0 && (
            <Typography variant="subtitle1">
              You don&apos;t have any other wishlists to move this idea to
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" disabled={isSubmitting}>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default MoveIdeaModal;
