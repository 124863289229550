import { AxiosError, isAxiosError } from "axios";

import type { ActiveRecord } from "@/types/rails";
import useRequest, { RequestConfig } from "./useRequest";

export type ApiRequestConfig = Omit<RequestConfig, "data">;

export type RestApiErrorMessages<Type> = {
  [Property in keyof Type]: string;
};

export type ApiError = Error | AxiosError;

export const getErrorMessages = <Type>(error: ApiError) => {
  if (isAxiosError(error) && error.response?.data?.errors) {
    const errorMessages = error.response.data.errors as RestApiErrorMessages<Type>;
    return errorMessages;
  } else {
    throw error;
  }
};

const useRestApi = <Type extends ActiveRecord>(baseUrl: string) => {
  const request = useRequest();

  return {
    baseUrl,
    index: <Override = Type[]>(config: ApiRequestConfig = {}) => {
      return request.get<Override>(baseUrl, config);
    },
    show: <Override = Type>(id: ActiveRecord["id"], config: ApiRequestConfig = {}) => {
      return request.get<Override>(`${baseUrl}/${id}`, config);
    },
    create: <Override = Type>(data: object, config: ApiRequestConfig = {}) => {
      return request.post<Override>(baseUrl, { data, ...config });
    },
    update: <Override = Type>(
      id: ActiveRecord["id"],
      data: object,
      config: ApiRequestConfig = {}
    ) => {
      return request.patch<Override>(`${baseUrl}/${id}`, { data, ...config });
    },
    destroy: <Override = void>(id: ActiveRecord["id"], config: ApiRequestConfig = {}) => {
      return request.delete<Override>(`${baseUrl}/${id}`, config);
    }
  };
};

export default useRestApi;
