import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import hasQueryData from "@/lib/hasQueryData";
import type { IdeaRecord } from "@/types/ideas";
import type { PurchaseRecord } from "@/types/purchases";
import { WishlistRecord } from "@/types/wishlists";
import { getIdeasQueryKey } from "./useIdeas";
import useRestApi from "./useRestApi";
export { useWishlistIdParam } from "./useWishlists";

export const usePurchasesApi = () => {
  const api = useRestApi<PurchaseRecord>(`/api/purchases`);
  return {
    ...api,
    destroy: api.destroy<PurchaseRecord>
  };
};

export const getPurchasesQueryKey = () => {
  return ["purchases"];
};

export const useIndexPurchasesQuery = () => {
  const api = usePurchasesApi();
  return {
    queryKey: getPurchasesQueryKey(),
    queryFn: async () => {
      const response = await api.index();
      return response.data;
    }
  };
};

export const useFindPurchase = () => {
  const { data: purchases } = useQuery(useIndexPurchasesQuery());

  return (idea: IdeaRecord) => purchases?.find((purchase) => purchase.ideaId === idea.id);
};

export const useUpdateIdeaIsPurchased = () => {
  const queryClient = useQueryClient();

  return (wishlistId: WishlistRecord["id"], ideaId: IdeaRecord["id"], isPurchased: boolean) => {
    const queryKey = getIdeasQueryKey("shared", wishlistId);
    if (hasQueryData(queryClient, queryKey)) {
      queryClient.setQueryData(queryKey, (ideas: IdeaRecord[]) => {
        return ideas.map((idea) => (idea.id === ideaId ? { ...idea, isPurchased } : idea));
      });
    }
  };
};

export const useOnCreatePurchase = () => {
  const queryClient = useQueryClient();
  const updateIdeaIsPurchased = useUpdateIdeaIsPurchased();

  return (newPurchase: PurchaseRecord) => {
    updateIdeaIsPurchased(newPurchase.wishlistId, newPurchase.ideaId, true);

    const queryKey = getPurchasesQueryKey();
    if (hasQueryData(queryClient, queryKey)) {
      queryClient.setQueryData(queryKey, (purchases: PurchaseRecord[]) => {
        return [newPurchase, ...purchases];
      });
    }
  };
};

export const useCreatePurchaseMutation = () => {
  const api = usePurchasesApi();
  const onCreatePurchase = useOnCreatePurchase();

  return useMutation({
    mutationFn: async ({ ideaId }: { ideaId: IdeaRecord["id"] }) => {
      const response = await api.create({ ideaId });
      return response.data;
    },
    onSuccess: (purchase) => {
      onCreatePurchase(purchase);
    }
  });
};

export const useOnPurchaseDelete = () => {
  const queryClient = useQueryClient();
  const updateIdeaIsPurchased = useUpdateIdeaIsPurchased();

  return (purchaseToDelete: PurchaseRecord) => {
    updateIdeaIsPurchased(purchaseToDelete.wishlistId, purchaseToDelete.ideaId, false);

    const queryKey = getPurchasesQueryKey();
    if (hasQueryData(queryClient, queryKey)) {
      queryClient.setQueryData(queryKey, (purchases: PurchaseRecord[]) => {
        return purchases.filter((purchase) => purchase.id !== purchaseToDelete.id);
      });
    }
  };
};

export const useDeletePurchaseMutation = (purchaseToDelete: PurchaseRecord) => {
  const api = usePurchasesApi();
  const onDeletePurchase = useOnPurchaseDelete();

  return useMutation({
    mutationFn: async () => {
      const response = await api.destroy(purchaseToDelete.id);
      return response.data;
    },
    onSuccess: () => {
      onDeletePurchase(purchaseToDelete);
    }
  });
};
