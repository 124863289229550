import { DragDropContext, Droppable, OnDragEndResponder } from "@hello-pangea/dnd";
import { Stack } from "@mui/material";
import React from "react";

import { useReorderIdeasMutation } from "@/hooks/useIdeas";
import type { IdeaRecord } from "@/types/ideas";
import type { WishlistCategory, WishlistRecord } from "@/types/wishlists";

import DraggableIdea from "@/components/DraggableIdea";
import BlankSlate from "@/components/IdeaList/BlankSlate";

export type DraggableIdealListProps = {
  category: WishlistCategory;
  wishlistId: WishlistRecord["id"];
  ideas: IdeaRecord[];
};

const DraggableIdealList = ({ category, wishlistId, ideas }: DraggableIdealListProps) => {
  const reorderMutation = useReorderIdeasMutation(category, wishlistId);
  const hasIdeas = ideas.length > 0;

  const reorderIdeas = (startIndex: number, endIndex: number) => {
    const result = Array.from(ideas);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const handleDragEnd: OnDragEndResponder = (result) => {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;

    const reorderedIdeas = reorderIdeas(result.source.index, result.destination!.index);
    reorderMutation.mutate(reorderedIdeas);
  };

  if (!hasIdeas) {
    return <BlankSlate category={category} />;
  } else {
    return (
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="ideas">
          {(provided, _snapshot) => (
            <Stack ref={provided.innerRef} {...provided.droppableProps}>
              {ideas.map((idea, index) => (
                <DraggableIdea key={idea.id} idea={idea} index={index} />
              ))}
              {provided.placeholder}
            </Stack>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
};

export default DraggableIdealList;
