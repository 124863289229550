import { OpenInNew as OpenInNewIcon } from "@mui/icons-material";
import type { ButtonProps } from "@mui/material";
import { Button } from "@mui/material";
import React from "react";

import useOpenExternalLink from "@/hooks/useExternalLink";

type OpenLinkButtonProps = Omit<ButtonProps, "href"> & {
  href: string | null;
};

const OpenLinkButton = ({ href, ...props }: OpenLinkButtonProps) => {
  if (!href) return null;

  const handleOpenLink = useOpenExternalLink();

  return (
    <Button onClick={handleOpenLink(href)} startIcon={<OpenInNewIcon />} {...props}>
      Open Link
    </Button>
  );
};

export default OpenLinkButton;
