import { Public as PublicIcon } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  LinearProgress,
  TextField
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";

import {
  getErrorMessages,
  useCreateExternalListMutation,
  useUpdateExternalListMutation
} from "@/hooks/useExternalLists";
import type { ApiError } from "@/hooks/useRestApi";
import type { ExternalListRecord } from "@/types/external-lists";
import type { WishlistCategory, WishlistRecord } from "@/types/wishlists";

type ExternalListProps = {
  category: WishlistCategory;
  wishlistId: WishlistRecord["id"];
  open: boolean;
  onClose: () => void;
  externalList?: ExternalListRecord;
};

type ExternalExternalListData = {
  name: ExternalListRecord["name"];
  comment: ExternalListRecord["comment"];
  url: ExternalListRecord["url"];
};

const ExternalListForm = ({
  category,
  wishlistId,
  open,
  onClose,
  externalList
}: ExternalListProps) => {
  const {
    register,
    formState: { errors, isSubmitting },
    setError,
    reset,
    handleSubmit
  } = useForm<ExternalExternalListData>({
    defaultValues: {
      name: externalList?.name || "",
      comment: externalList?.comment || "",
      url: externalList?.url || ""
    }
  });
  const nameRef = useRef<HTMLInputElement>();

  const isCreate = typeof externalList === "undefined";
  const createMutation = useCreateExternalListMutation(category, wishlistId);
  const updateMutation = useUpdateExternalListMutation(category, wishlistId);
  const verb = isCreate ? "Add" : "Update";

  useEffect(() => {
    setTimeout(() => {
      nameRef.current?.focus();
    }, 100);
  }, []);

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleError = (error: ApiError) => {
    const messages = getErrorMessages(error);
    if (messages?.name) setError("name", { message: messages.name });
    if (messages?.comment) setError("comment", { message: messages.comment });
    if (messages?.url) setError("url", { message: messages.url });
    reset(undefined, { keepValues: true, keepErrors: true });
  };

  const onSubmit: SubmitHandler<ExternalExternalListData> = (data) => {
    if (isCreate) {
      return createMutation.mutate(data, {
        onSuccess: () => {
          handleClose();
        },
        onError: handleError
      });
    } else {
      return updateMutation.mutate(
        { externalListId: externalList.id, data },
        {
          onSuccess: () => {
            handleClose();
          },
          onError: handleError
        }
      );
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{verb} External List</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            type="text"
            margin="normal"
            autoComplete="off"
            required
            fullWidth
            inputRef={nameRef}
            slotProps={{ input: { inputProps: { "data-1p-ignore": true } } }}
            {...register("name")}
            error={!!errors.name?.message}
            helperText={errors.name?.message}
          />
          <TextField
            label="Comment"
            type="text"
            margin="normal"
            autoComplete="off"
            fullWidth
            multiline
            {...register("comment")}
            error={!!errors.comment?.message}
            helperText={errors.comment?.message}
          />
          <TextField
            label="Link"
            type="text"
            margin="normal"
            autoComplete="off"
            fullWidth
            multiline
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <PublicIcon />
                  </InputAdornment>
                )
              }
            }}
            {...register("url")}
            error={!!errors.url?.message}
            helperText={errors.url?.message}
          />
          {isSubmitting && <LinearProgress />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" disabled={isSubmitting}>
            {verb}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ExternalListForm;
