import { Box, Card, CardContent, List, Typography } from "@mui/material";
import React from "react";

import ExternaList from "@/components/Wishlist/ExternalLists/ExternalList";
import { ExternalListRecord } from "@/types/external-lists";

type ExternalListsProps = {
  externalLists: ExternalListRecord[];
};

const ExternalLists = ({ externalLists }: ExternalListsProps) => {
  return (
    <Box marginBottom={3}>
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h6" marginBottom={1}>
            External Lists
          </Typography>
          <List sx={{ py: 0 }}>
            {externalLists.map((externalList) => (
              <ExternaList key={externalList.id} externalList={externalList} />
            ))}
          </List>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ExternalLists;
