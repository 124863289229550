import { useQuery } from "@tanstack/react-query";
import React from "react";

import PurchasedIdeaList from "@/components/PurchasedIdeaList";
import { useIndexPurchasesQuery } from "@/hooks/usePurchases";

const PurchasedIdeas = () => {
  const { data: purchases } = useQuery(useIndexPurchasesQuery());
  return <PurchasedIdeaList purchases={purchases || []} />;
};

export default PurchasedIdeas;
