import { Box, Stack } from "@mui/material";
import React from "react";

import type { IdeaRecord } from "@/types/ideas";
import type { WishlistCategory, WishlistRecord } from "@/types/wishlists";

import Idea from "@/components/Idea";
import BlankSlate from "./BlankSlate";

export type IdeaListProps = {
  category: WishlistCategory;
  wishlistId: WishlistRecord["id"];
  ideas: IdeaRecord[];
};

const IdeaList = ({ category, ideas }: IdeaListProps) => {
  const hasIdeas = ideas.length > 0;

  if (!hasIdeas) {
    return <BlankSlate category={category} />;
  } else {
    return (
      <Stack>
        {ideas.map((idea) => (
          <Box key={idea.id} marginBottom={3}>
            <Idea idea={idea} />
          </Box>
        ))}
      </Stack>
    );
  }
};

export default IdeaList;
