import { useCreatePurchaseMutation } from "@/hooks/usePurchases";
import { IdeaRecord } from "@/types/ideas";
import { Check as CheckIcon } from "@mui/icons-material";
import type { ButtonProps } from "@mui/material";
import { Button } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import React from "react";

type PurchaseIdeaButtonProps = ButtonProps & {
  idea: IdeaRecord;
};

const PurchaseIdeaButton = ({ idea, ...props }: PurchaseIdeaButtonProps) => {
  const confirm = useConfirm();
  const createPurchase = useCreatePurchaseMutation();

  const handlePurchaseIdea = () => {
    confirm({
      title: "Flag as purchased?",
      description: "This will flag the idea as purchased",
      confirmationButtonProps: { autoFocus: true }
    })
      .then(() => {
        createPurchase.mutate({ ideaId: idea.id });
        // TODO: Handle error
      })
      .catch(() => {});
  };

  return (
    <Button onClick={handlePurchaseIdea} startIcon={<CheckIcon />} {...props}>
      I purchased this
    </Button>
  );
};

export default PurchaseIdeaButton;
