import { Stack } from "@mui/material";
import React from "react";

import WishlistTabs from "@/components/WishlistTabs";
import Idea from "./Idea";

import type { PurchaseRecord } from "@/types/purchases";
import BlankSlate from "./BlankSlate";

type PurchasedIdeaListProps = {
  purchases: PurchaseRecord[];
};

const PurchasedIdeaList = ({ purchases }: PurchasedIdeaListProps) => {
  const hasPurchases = purchases.length > 0;

  return (
    <>
      <WishlistTabs category="purchased" />
      {hasPurchases && (
        <Stack spacing={3}>
          {purchases.map((purchase) => (
            <Idea key={purchase.id} purchase={purchase} />
          ))}
        </Stack>
      )}
      {!hasPurchases && <BlankSlate />}
    </>
  );
};

export default PurchasedIdeaList;
