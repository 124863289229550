import {
  Delete as DeleteIcon,
  DriveFileMove as DriveFileMoveIcon,
  Edit as EditIcon,
  MoreVert as MoreVertIcon
} from "@mui/icons-material";
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import React, { useId, useState } from "react";

import IdeaForm from "@/components/IdeaForm";
import { useDeleteIdeaMutation } from "@/hooks/useIdeas";
import useToggle from "@/hooks/useToggle";
import type { IdeaRecord } from "@/types/ideas";
import MoveIdeaModal from "./MoveIdeaModal";

type ManageDropdownProps = {
  idea: IdeaRecord;
};

const ManageDropdown = ({ idea }: ManageDropdownProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClose = () => setAnchorEl(null);
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const menuId = useId();
  const confirm = useConfirm();

  const { open: formOpen, handleOpen: handleFormOpen, handleClose: handleFormClose } = useToggle();

  const handleEdit = () => {
    handleClose();
    handleFormOpen();
  };

  const {
    open: moveModalOpen,
    handleOpen: handleMoveModalOpen,
    handleClose: handleMoveModalClose
  } = useToggle();

  const handleMove = () => {
    handleClose();
    handleMoveModalOpen();
  };

  const deleteMutation = useDeleteIdeaMutation(idea.category, idea.wishlistId, idea.id);

  const handleDelete = () => {
    handleClose();

    confirm({
      description: `This will permanently delete ${idea.name}`,
      confirmationButtonProps: { autoFocus: true }
    })
      .then(() => {
        deleteMutation.mutate();
      })
      .catch(() => {});
  };

  return (
    <>
      <IconButton
        aria-label="manage idea"
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleOpen}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleMove}>
          <ListItemIcon>
            <DriveFileMoveIcon />
          </ListItemIcon>
          <ListItemText>Move</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleEdit}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
      {formOpen && (
        <IdeaForm
          category={idea.category}
          wishlistId={idea.wishlistId}
          idea={idea}
          open={formOpen}
          onClose={handleFormClose}
        />
      )}
      {moveModalOpen && (
        <MoveIdeaModal idea={idea} open={moveModalOpen} onClose={handleMoveModalClose} />
      )}
    </>
  );
};

export default ManageDropdown;
