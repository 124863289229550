import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Stack,
  Typography
} from "@mui/material";
import React from "react";

import OpenLinkButton from "@/components/OpenLinkButton";
import UndoPurchaseIdeaButton from "@/components/UndoPurchaseIdeaButton";
import getRelativeDateTimeString from "@/lib/getRelativeDateTimeString";
import getStringAvatar from "@/lib/getStringAvatar";
import type { PurchaseRecord } from "@/types/purchases";

type IdeaProps = {
  purchase: PurchaseRecord;
};

const Idea = ({ purchase }: IdeaProps) => {
  const {
    cachedIdeaName: name,
    cachedIdeaComment: comment,
    cachedIdeaUrl: url,
    cachedIdeaHasCost: showCost,
    cachedIdeaCost: cost,
    cachedWishlistName: wishlistName,
    purchasedForName,
    purchasedForUsername,
    createdAt: purchasedAt
  } = purchase;

  const purchasedDescription =
    `Purchased ${getRelativeDateTimeString(purchasedAt)} ` +
    `from ${wishlistName} ` +
    `for ${purchasedForName} ` +
    `(@${purchasedForUsername})`;

  const showComment = (comment?.length || 0) > 0;
  const showContent = showComment || showCost;

  return (
    <Card sx={{ position: "relative" }}>
      <CardHeader
        avatar={<Avatar {...getStringAvatar(name)} />}
        title={name}
        titleTypographyProps={{ variant: "h4" }}
        subheader={purchasedDescription}
      />

      {showContent && (
        <CardContent>
          <Stack spacing={1.5}>
            {showComment && (
              <Typography variant="body1" color="text.secondary" fontStyle="italic">
                {comment}
              </Typography>
            )}
            {showCost && (
              <Typography variant="body2" color="text.secondary">
                Estimated cost: {cost}
              </Typography>
            )}
          </Stack>
        </CardContent>
      )}

      <CardActions>
        <UndoPurchaseIdeaButton purchase={purchase} />
        {url && <OpenLinkButton href={url} />}
      </CardActions>
    </Card>
  );
};

export default Idea;
