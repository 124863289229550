import { useMutation, useQueryClient } from "@tanstack/react-query";

import hasQueryData from "@/lib/hasQueryData";
import { ExternalListRecord, ExternalListRecordPartial } from "@/types/external-lists";
import type { WishlistCategory, WishlistRecord } from "@/types/wishlists";
import useRestApi, { ApiError, getErrorMessages as getRestApiErrorMessages } from "./useRestApi";
import { getWishlistsQueryKey } from "./useWishlists";
export { useWishlistIdParam } from "./useWishlists";

export const getErrorMessages = (error: ApiError) => {
  return getRestApiErrorMessages<ExternalListRecord>(error);
};

export const getExternalListsQueryKey = (
  category: WishlistCategory,
  wishlistId: WishlistRecord["id"]
) => {
  return [...getWishlistsQueryKey(category, wishlistId), "external_lists"];
};

export const sortExternalLists = (lists: ExternalListRecord[]) => {
  return lists.sort((listA, listB) => {
    if (listA.name === listB.name) {
      // If the names are the same, sort by ID (newest first)
      return listA.id - listB.id;
    } else {
      // Otherwise, sort by name
      return listA.name.localeCompare(listB.name, undefined, { sensitivity: "base" });
    }
  });
};

const useExternalListsApi = (category: WishlistCategory, wishlistId: WishlistRecord["id"]) => {
  return useRestApi<ExternalListRecord>(`/api/${category}_wishlists/${wishlistId}/external_lists`);
};

export const useIndexExternalListsQuery = (
  category: WishlistCategory,
  wishlistId: WishlistRecord["id"]
) => {
  const api = useExternalListsApi(category, wishlistId);
  return {
    queryKey: getExternalListsQueryKey(category, wishlistId),
    queryFn: async () => {
      const response = await api.index();
      return response.data;
    }
  };
};

export const useOnExternalListCreate = (
  category: WishlistCategory,
  wishlistId: WishlistRecord["id"]
) => {
  const queryClient = useQueryClient();

  return (newList: ExternalListRecord) => {
    if (hasQueryData(queryClient, getExternalListsQueryKey(category, wishlistId))) {
      queryClient.setQueryData(
        getExternalListsQueryKey(category, wishlistId),
        (currentLists: ExternalListRecord[]) => {
          return sortExternalLists([...currentLists, newList]);
        }
      );
    }
  };
};

export const useCreateExternalListMutation = (
  category: WishlistCategory,
  wishlistId: WishlistRecord["id"]
) => {
  const api = useExternalListsApi(category, wishlistId);
  const onExternalListCreate = useOnExternalListCreate(category, wishlistId);

  return useMutation({
    mutationFn: async (values: ExternalListRecordPartial) => {
      const response = await api.create(values);
      return response.data;
    },
    onSuccess: (newList) => {
      onExternalListCreate(newList);
    }
  });
};

export const useOnExternalListUpdate = (
  category: WishlistCategory,
  wishlistId: WishlistRecord["id"]
) => {
  const queryClient = useQueryClient();
  const onExternalListDelete = useOnExternalListDelete(category, wishlistId);

  return (updatedList: ExternalListRecord) => {
    if (updatedList.wishlistId !== wishlistId) {
      onExternalListDelete(updatedList.id);
    }

    const queryKey = getExternalListsQueryKey(category, updatedList.wishlistId);
    if (hasQueryData(queryClient, queryKey)) {
      queryClient.setQueryData(queryKey, (currentLists: ExternalListRecord[]) => {
        const otherLists = currentLists.filter((currentList) => currentList.id !== updatedList.id);
        return sortExternalLists([...otherLists, updatedList]);
      });
    }
  };
};

export const useUpdateExternalListMutation = (
  category: WishlistCategory,
  wishlistId: WishlistRecord["id"]
) => {
  const api = useExternalListsApi(category, wishlistId);
  const onExternalListUpdate = useOnExternalListUpdate(category, wishlistId);

  return useMutation({
    mutationFn: async ({
      externalListId,
      data
    }: {
      externalListId: ExternalListRecord["id"];
      data: ExternalListRecordPartial;
    }) => {
      const response = await api.update(externalListId, data);
      return response.data;
    },
    onSuccess: (updatedList) => {
      onExternalListUpdate(updatedList);
    }
  });
};

export const useOnExternalListDelete = (
  category: WishlistCategory,
  wishlistId: WishlistRecord["id"]
) => {
  const queryClient = useQueryClient();

  return (externalListId: ExternalListRecord["id"]) => {
    if (hasQueryData(queryClient, getExternalListsQueryKey(category, wishlistId))) {
      queryClient.setQueryData(
        getExternalListsQueryKey(category, wishlistId),
        (currentLists: ExternalListRecord[]): ExternalListRecord[] => {
          return currentLists.filter((currentList) => currentList.id !== Number(externalListId));
        }
      );
    }
  };
};

export const useDeleteExternalListMutation = (
  category: WishlistCategory,
  wishlistId: WishlistRecord["id"],
  externalListId: ExternalListRecord["id"]
) => {
  const api = useExternalListsApi(category, wishlistId);
  const onExternalListDelete = useOnExternalListDelete(category, wishlistId);

  return useMutation({
    mutationFn: async () => {
      const response = await api.destroy(externalListId);
      return response.data;
    },
    onSuccess: () => {
      onExternalListDelete(externalListId);
    }
  });
};
