import { Check as CheckIcon } from "@mui/icons-material";
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Stack,
  Typography
} from "@mui/material";
import React from "react";

import OpenLinkButton from "@/components/OpenLinkButton";
import PurchaseIdeaButton from "@/components/PurchaseIdeaButton";
import UndoPurchaseIdeaButton from "@/components/UndoPurchaseIdeaButton";
import { useFindPurchase } from "@/hooks/usePurchases";
import getStringAvatar from "@/lib/getStringAvatar";
import type { IdeaRecord } from "@/types/ideas";
import ManageDropdown from "./ManageDropdown";

type IdeaProps = {
  idea: IdeaRecord;
};

const Idea = ({ idea }: IdeaProps) => {
  const findPurchase = useFindPurchase();
  const purchase = findPurchase(idea);

  const showComment = (idea.comment?.length || 0) > 0;
  const showCost = idea.hasCost;
  const showContent = showComment || showCost;

  const showPurchaseIdeaButton = idea.category == "shared" && !idea.isPurchased;
  const showUndoPurchaseIdeaButton = idea.category == "shared" && purchase;
  const showOpenLink = (idea.url?.length || 0) > 0;
  const showActions = showPurchaseIdeaButton || showUndoPurchaseIdeaButton || showOpenLink;

  const showIsPurchasedOverlay = idea.category == "shared" && idea.isPurchased;
  const buttonColor = showIsPurchasedOverlay ? "secondary" : "primary";

  return (
    <Card sx={{ position: "relative" }}>
      {showIsPurchasedOverlay && (
        <Stack
          alignItems="center"
          justifyContent="center"
          spacing={0.5}
          bgcolor="success.light"
          color="success.dark"
          zIndex={1}
          sx={{ position: "absolute", opacity: "30%", inset: 0, pointerEvents: "none" }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <CheckIcon />
            <Typography variant="h3">Purchased</Typography>
          </Stack>
        </Stack>
      )}
      <CardHeader
        avatar={<Avatar {...getStringAvatar(idea.name)} />}
        title={idea.name}
        titleTypographyProps={{ variant: "h4" }}
        action={idea.category === "my" && <ManageDropdown idea={idea} />}
      />

      {showContent && (
        <CardContent>
          <Stack spacing={1.5}>
            {showComment && (
              <Typography variant="body1" color="text.secondary" fontStyle="italic">
                {idea.comment}
              </Typography>
            )}
            {showCost && (
              <Typography variant="body2" color="text.secondary">
                Estimated cost: {idea.cost}
              </Typography>
            )}
          </Stack>
        </CardContent>
      )}

      {showActions && (
        <CardActions>
          {showPurchaseIdeaButton && <PurchaseIdeaButton idea={idea} color={buttonColor} />}
          {showUndoPurchaseIdeaButton && (
            <UndoPurchaseIdeaButton purchase={purchase} color={buttonColor} />
          )}
          {showOpenLink && <OpenLinkButton href={idea.url} color={buttonColor} />}
        </CardActions>
      )}
    </Card>
  );
};

export default Idea;
