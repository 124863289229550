import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  TextField
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";

import useRequest from "@/hooks/useRequest";
import { getErrorMessages } from "@/hooks/useRestApi";
import type { UserRecordPartial } from "@/types/users";

type AccountPasswordFormProps = {
  open: boolean;
  onClose: () => void;
};

type AccountPasswordFormData = {
  passwordChallenge: UserRecordPartial["passwordChallenge"];
  password: UserRecordPartial["password"];
  passwordConfirmation: UserRecordPartial["passwordConfirmation"];
};

const AccountPasswordForm = ({ open, onClose }: AccountPasswordFormProps) => {
  const request = useRequest();
  const {
    register,
    formState: { errors, isSubmitting },
    setError,
    reset,
    handleSubmit
  } = useForm<AccountPasswordFormData>({
    defaultValues: {
      passwordChallenge: "",
      password: "",
      passwordConfirmation: ""
    }
  });
  const challengeRef = useRef<HTMLInputElement>();

  useEffect(() => {
    setTimeout(() => {
      challengeRef.current?.focus();
    }, 100);
  }, []);

  const handleClose = () => {
    reset();
    onClose();
  };

  const onSubmit: SubmitHandler<AccountPasswordFormData> = (data) =>
    request
      .patch("/api/account/password", { data })
      .then(() => {
        handleClose();
      })
      .catch((error) => {
        const messages = getErrorMessages<UserRecordPartial>(error);
        if (messages.passwordChallenge)
          setError("passwordChallenge", { message: messages.passwordChallenge });
        if (messages.password) setError("password", { message: messages.password });
        if (messages.passwordConfirmation)
          setError("passwordConfirmation", { message: messages.passwordConfirmation });
        reset(undefined, { keepValues: true, keepErrors: true });
      });

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Change email</DialogTitle>
        <DialogContent>
          <TextField
            label="Current Password"
            type="password"
            margin="normal"
            autoComplete="current-password"
            fullWidth
            required
            inputRef={challengeRef}
            {...register("passwordChallenge")}
            error={!!errors.passwordChallenge?.message}
            helperText={errors.passwordChallenge?.message}
          />
          <TextField
            label="New Password"
            type="password"
            margin="normal"
            autoComplete="new-password"
            fullWidth
            required
            {...register("password")}
            error={!!errors.password?.message}
            helperText={errors.password?.message}
          />
          <TextField
            label="New Password Confirmation"
            type="password"
            margin="normal"
            autoComplete="confirm-password"
            fullWidth
            required
            {...register("passwordConfirmation")}
            error={!!errors.passwordConfirmation?.message}
            helperText={errors.passwordConfirmation?.message}
          />
          {isSubmitting && <LinearProgress />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" disabled={isSubmitting}>
            Update
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AccountPasswordForm;
