import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";

import { getIdeasQueryKey } from "@/hooks/useIdeas";
import hasQueryData from "@/lib/hasQueryData";
import type { WishlistCategory, WishlistRecord, WishlistRecordPartial } from "@/types/wishlists";
import useRequest from "./useRequest";
import useRestApi, { ApiError, getErrorMessages as getRestApiErrorMessages } from "./useRestApi";

export type { WishlistCategory } from "@/types/wishlists";

export const getErrorMessages = (error: ApiError) => {
  return getRestApiErrorMessages<WishlistRecord>(error);
};

export const getWishlistsPath = (category: WishlistCategory, wishlistId?: WishlistRecord["id"]) => {
  return typeof wishlistId === "undefined"
    ? `/${category}_wishlists`
    : `/${category}_wishlists/${wishlistId}`;
};

export const getWishlistsQueryKey = (category: WishlistCategory, wishlistId?: number | string) => {
  return typeof wishlistId === "undefined"
    ? [`${category}_wishlists`]
    : [`${category}_wishlists`, wishlistId.toString()];
};

export const useWishlistsApi = (category: WishlistCategory) => {
  return useRestApi<WishlistRecord>(`/api/${category}_wishlists`);
};

export const useWishlistIdParam = () => {
  const params = useParams();
  const wishlistId = parseInt(params.wishlistId || "");
  if (isNaN(wishlistId)) {
    return undefined;
  } else {
    return wishlistId;
  }
};

export const sortWishlists = (wishlists: WishlistRecord[]) => {
  return wishlists.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: "base" }));
};

export const useIndexWishlistsQuery = (category: WishlistCategory) => {
  const api = useWishlistsApi(category);
  return {
    queryKey: getWishlistsQueryKey(category),
    queryFn: async () => {
      const response = await api.index();
      return sortWishlists(response.data);
    }
  };
};

export const useShowWishlistsQuery = (
  category: WishlistCategory,
  wishlistId: WishlistRecord["id"]
) => {
  const api = useWishlistsApi(category);
  return {
    queryKey: getWishlistsQueryKey(category, wishlistId),
    queryFn: async () => {
      const response = await api.show(wishlistId);
      return response.data;
    }
  };
};

export const useOnWishlistCreate = (category: WishlistCategory) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return (newWishlist: WishlistRecord) => {
    if (hasQueryData(queryClient, getWishlistsQueryKey(category))) {
      queryClient.setQueryData(
        getWishlistsQueryKey(category),
        (currentWishlists: WishlistRecord[]) => {
          return sortWishlists([...currentWishlists, newWishlist]);
        }
      );
    }

    queryClient.setQueryData(getWishlistsQueryKey(category, newWishlist.id), newWishlist);

    navigate(getWishlistsPath(category, newWishlist.id));
  };
};

export const useCreateWishlistMutation = (category: WishlistCategory) => {
  const api = useWishlistsApi(category);
  const onWishlistCreate = useOnWishlistCreate(category);

  return useMutation({
    mutationFn: async (values: WishlistRecordPartial) => {
      const response = await api.create(values);
      return response.data;
    },
    onSuccess: (newWishlist) => {
      onWishlistCreate(newWishlist);
    }
  });
};

export const useOnWishlistUpdate = (category: WishlistCategory) => {
  const queryClient = useQueryClient();

  return (updatedWishlist: WishlistRecord) => {
    if (hasQueryData(queryClient, getWishlistsQueryKey(category))) {
      queryClient.setQueryData(
        getWishlistsQueryKey(category),
        (currentWishlists: WishlistRecord[]) => {
          const otherWishlists = currentWishlists.filter(
            (currentWishlist) => currentWishlist.id !== updatedWishlist.id
          );
          return sortWishlists([...otherWishlists, updatedWishlist]);
        }
      );
    }

    queryClient.setQueryData(getWishlistsQueryKey(category, updatedWishlist.id), updatedWishlist);
  };
};

export const useUpdateWishlistMutation = (category: WishlistCategory) => {
  const api = useWishlistsApi(category);
  const onWishlistUpdate = useOnWishlistUpdate(category);

  return useMutation({
    mutationFn: async ({
      wishlistId,
      data
    }: {
      wishlistId: WishlistRecord["id"];
      data: WishlistRecordPartial;
    }) => {
      const response = await api.update(wishlistId, data);
      return response.data;
    },
    onSuccess: (updatedWishlist) => {
      onWishlistUpdate(updatedWishlist);
    }
  });
};

export const useOnWishlistDelete = (category: WishlistCategory) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return (wishlistId: WishlistRecord["id"]) => {
    if (hasQueryData(queryClient, getWishlistsQueryKey(category))) {
      queryClient.setQueryData(
        getWishlistsQueryKey(category),
        (currentWishlists: WishlistRecord[]): WishlistRecord[] => {
          return currentWishlists.filter(
            (currentWishlist) => currentWishlist.id !== Number(wishlistId)
          );
        }
      );
    }

    queryClient.removeQueries({
      queryKey: getWishlistsQueryKey(category, wishlistId)
    });

    navigate(getWishlistsPath(category));
  };
};

export const useDeleteWishlistMutation = (
  category: WishlistCategory,
  wishlistId: WishlistRecord["id"]
) => {
  const api = useWishlistsApi(category);
  const onWishlistDelete = useOnWishlistDelete(category);

  return useMutation({
    mutationFn: () => api.destroy(wishlistId),
    onSuccess: () => {
      onWishlistDelete(wishlistId);
    }
  });
};

export const useOnRemovePurchasedIdeas = (category: WishlistCategory) => {
  const queryClient = useQueryClient();

  return (wishlistId: WishlistRecord["id"]) => {
    queryClient.invalidateQueries({ queryKey: getIdeasQueryKey(category, wishlistId) });
  };
};

export const useRemovePurchasedIdeasMutation = (
  category: WishlistCategory,
  wishlistId: WishlistRecord["id"]
) => {
  const request = useRequest();
  const onRemovePurchasedIdeas = useOnRemovePurchasedIdeas(category);

  return useMutation({
    mutationFn: () =>
      request.patch<void>(`/api/${category}_wishlists/${wishlistId}/hide_purchases`),
    onSuccess: () => {
      onRemovePurchasedIdeas(wishlistId);
    }
  });
};
