import { Undo as UndoIcon } from "@mui/icons-material";
import type { ButtonProps } from "@mui/material";
import { Button } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import React from "react";

import { useDeletePurchaseMutation } from "@/hooks/usePurchases";
import type { PurchaseRecord } from "@/types/purchases";

type UndoPurchaseIdeaButtonProps = ButtonProps & {
  purchase: PurchaseRecord;
};

const UndoPurchaseIdeaButton = ({ purchase, ...props }: UndoPurchaseIdeaButtonProps) => {
  const confirm = useConfirm();
  const deletePurchase = useDeletePurchaseMutation(purchase);

  const handleUndoPurchase = () => {
    confirm({
      title: "Undo purchase?",
      description: "Do you want to cancel your purchase?",
      confirmationButtonProps: { autoFocus: true }
    })
      .then(() => {
        deletePurchase.mutate();
        // TODO: Handle error
      })
      .catch(() => {});
  };

  return (
    <Button onClick={handleUndoPurchase} startIcon={<UndoIcon />} size="small" {...props}>
      Undo purchase
    </Button>
  );
};

export default UndoPurchaseIdeaButton;
