import { Draggable } from "@hello-pangea/dnd";
import { DragIndicator as DragIndicatorIcon } from "@mui/icons-material";
import { Box, IconButton, Stack } from "@mui/material";
import React from "react";

import Idea from "@/components/Idea";
import type { IdeaRecord } from "@/types/ideas";

type IdeaProps = {
  idea: IdeaRecord;
  index: number;
};

const DraggableIdea = ({ idea, index }: IdeaProps) => {
  return (
    <Draggable draggableId={idea.id.toString()} index={index}>
      {(provided, _snapshot) => (
        <Box
          marginBottom={3}
          ref={provided.innerRef}
          {...provided.draggableProps}
          sx={{ position: "relative" }}
        >
          <Stack
            justifyContent="center"
            sx={{
              position: "absolute",
              left: 0,
              top: 0,
              bottom: 0,
              transform: "translateX(-50%)",
              zIndex: 2
            }}
          >
            <IconButton
              size="large"
              color="primary"
              sx={{ cursor: "grab", opacity: "75%" }}
              {...provided.dragHandleProps}
            >
              <DragIndicatorIcon />
            </IconButton>
          </Stack>
          <Idea idea={idea} />
        </Box>
      )}
    </Draggable>
  );
};

export default DraggableIdea;
